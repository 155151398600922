import React from 'react'
import CabaretLayout from '../../../components/cabaretlayout'
import CabaretInfosNavigation from '../../../components/Infos/CabaretInfos/CabaretInfosNavigations'
import { StaticImage } from 'gatsby-plugin-image'

const CabaretInfosGagnants = () => (
    <CabaretLayout>
        <div className='cabaret-infos'>

        <CabaretInfosNavigation />

        <h1>Gagnant.es 2024</h1>

        <div className='cabaret-infos-inner'>
            <div className='cabaret-infos-inner-round-image'>
                <StaticImage alt='Auberge des balcons' className="max-w-[1856px] max-h-[1044px] mt-8 mb-12" src="../../../images/cabaret/gagnants.png" />
            </div>

            <h2>Autres prix remis - Grande finale 2024</h2>
            <div className='cabaret-infos-inner-list mb-6'>
                <ul>
                    <li><h3><b>Prix du Porte-Parole:</b> anais constantin</h3></li>
                    <li><h3><b>Prix Le Pantoum:</b> cure-pipe</h3></li>
                    <li><h3><b>Prix coup de coeur Télé-Québec:</b> bermuda</h3></li>
                    <li><h3><b>Prix Festival international de la chanson de Granby:</b> bermuda</h3></li>
                    <li><h3><b>Prix Domaine Forget de Charlevoix:</b> halde</h3></li>
                    <li><h3><b>Prix Le Phoque Off:</b> anais constantin</h3></li>
                    <li><h3><b>Prix CISM:</b> anais constantin</h3></li>
                </ul>
            </div>

            <h2>Prestations rémunérées dans les programmations suivantes</h2>
            <div className='cabaret-infos-inner-list'>
                <ul>
                    <li><h3><b>Prix Festival en Chanson de Petite-Vallée:</b> bermuda</h3></li>
                    <li><h3><b>Prix Festival de la Chanson de Tadoussac:</b> bermuda</h3></li>
                    <li><h3><b>Prix Vieux Bureau de Poste (Lévis):</b> anais constantin</h3></li>
                    <li><h3><b>Prix Le Zaricot (St-Hyacinthe):</b> bermuda</h3></li>
                    <li><h3><b>Prix Le Minotaure:</b> halde</h3></li>
                    <li><h3><b>Prix Auberge Festive Sea Shack (Ste Anne des monts):</b> cure-pipe</h3></li>
                    <li><h3><b>Prix Cabane Panache:</b> anais constantin</h3></li>
                    <li><h3><b>Prix Bistro des Balcons:</b> bermuda</h3></li>
                    <li><h3><b>Prix La Chasse-Pinte, brasserie coopérative:</b> bermuda</h3></li>
                    <li><h3><b>Prix Festival Off:</b> cure-pire</h3></li>
                    <li><h3><b>Prix Festival BleuBleu (Édition 2025):</b> anais constantin</h3></li>
                </ul>
            </div>

        </div>

        </div>
    </CabaretLayout>
)

export default CabaretInfosGagnants
